<template>
  <ValidationObserver ref="validator" #default="{ validate }" tag="div" class="form-wrapper" role="form" aria-label="form" @change="checkSubmit()">
    <div class="ui active inverted dimmer page-loader" v-if="loading">
      <div class="ui medium text loader"><spinner :size="64" :loader="true" color /></div>
    </div>
    <h1 v-if="title">{{ title }}</h1>
    <p v-if="subtitle">{{ subtitle }}</p>
    <div v-if="iloSubTitles" class="ilo-subtitles">
      <div v-for="iloSubTitle in iloSubTitles" :key="iloSubTitle">{{ iloSubTitle }}</div>
    </div>
    <span v-if="info" class="sm">{{ info }}</span>
    <div class="field-wrapper" v-for="field in form" :key="field.name">
      <h1 v-if="shouldDisplaySectionName(field)">{{ displaySectionName(field) }}</h1>
      <ValidationProvider
        #default="{ valid, validated, touched, errors }"
        :rules="field.rules"
        tag="div"
        :class="{ [`field-${field.type}`]: true }"
        :mode="field.mode || 'aggressive'"
        v-if="shouldDisplay(field) && typeof field === 'object' && field.name"
      >
        <div class="field">
          <label :for="`${field.name}`" :aria-label="`${field.label}`">
            <span
              aria-hidden="true"
              class="material-icons valid"
              :class="{ visable: (valid && touched) || (valid && ![null, undefined, ''].includes(field.value)) }"
              v-if="field.type !== 'consent'"
            >
              done
            </span>
            <span aria-hidden="true" class="material-icons invalid" :class="{ visable: (!valid && touched) || (!valid && validated) }" v-if="field.type !== 'consent'">
              clear
            </span>
            <span class="empty" :class="{ visable: !valid && !touched }" v-if="field.type !== 'consent'"></span>
            {{ field.label }}
          </label>
          <div class="input-wrapper" :class="{ [`input-wrapper-${field.type}`]: true }">
            <span
              class="field-icon material-icons icon-left currency"
              v-if="field.icon && field.icon === 'currency' && field.iconPosition === 'left'"
              :class="{ 'file-icon': field.dataType && field.dataType === 'file' }"
            >
              {{ $config.whitelabel.country.currency.defaultCurrencySymbol }}
            </span>
            <span
              class="field-icon material-icons icon-left currency"
              v-else-if="field.icon && field.iconPosition === 'left'"
              :class="{ 'file-icon': field.dataType && field.dataType === 'file' }"
            >
              {{ field.icon }}
            </span>
            <MultiSelect
              :aria-label="`${field.label}`"
              v-if="field.type === 'multiselect'"
              :field="field"
              :value="field.value"
              rule="required"
              :class="{ error: Array.isArray(errors) && errors[0] }"
            />
            <FileInput
              v-if="field.type === 'input' && field.dataType && field.dataType === 'file'"
              :field="field"
              :value="field.files"
              @files-selected="event => $emit('files-selected', event)"
              @delete-file="event => $emit('delete-file', event)"
              :class="{ error: Array.isArray(errors) && errors[0] }"
            />
            <CompanySearch v-else-if="field.type === 'company-search'" :field="field" v-model="field.value" :class="{ error: Array.isArray(errors) && errors[0] }" />
            <DateInput
              v-model="field.value"
              :id="field.name"
              role="input"
              :name="field.name"
              :dataType="field.dataType"
              :returnType="field.returnType"
              :class="{
                'has-icon-left': field.icon && field.iconPosition === 'left',
                'has-icon-right': field.icon && field.iconPosition !== 'left',
                error: Array.isArray(errors) && errors[0]
              }"
              v-else-if="field.type === 'date' && (field.dataType === 'month' || field.dataType === 'day')"
            />
            <CurrencyField
              :field="field"
              role="input"
              v-model="field.value"
              :class="{
                'has-icon-left': field.icon && field.iconPosition === 'left',
                'has-icon-right': field.icon && field.iconPosition !== 'left',
                error: Array.isArray(errors) && errors[0]
              }"
              v-else-if="field.type === 'input' && field.dataType === 'currency'"
            />
            <input
              :aria-label="`${field.label}`"
              v-model="field.value"
              :id="field.name"
              :placeholder="field.placeholder"
              role="input"
              :name="field.name"
              :class="{
                'has-icon-left': field.icon && field.iconPosition === 'left',
                'has-icon-right': field.icon && field.iconPosition !== 'left',
                error: Array.isArray(errors) && errors[0]
              }"
              v-else-if="field.type === 'input'"
              :type="field.dataType || 'text'"
            />
            <input
              v-model="field.value"
              :id="field.name"
              :placeholder="field.placeholder"
              role="input"
              :name="field.name"
              :class="{ error: Array.isArray(errors) && errors[0] }"
              v-else-if="field.type === 'password'"
              type="password"
            />
            <input
              v-model="field.value"
              :id="field.name"
              :placeholder="field.placeholder"
              role="input"
              :name="field.name"
              :class="{ error: Array.isArray(errors) && errors[0] }"
              v-else-if="field.type === 'email'"
              type="email"
            />
            <textarea
              :aria-label="`${field.label}`"
              v-model="field.value"
              :id="field.name"
              :placeholder="field.placeholder"
              role="input"
              :name="field.name"
              :class="{ error: Array.isArray(errors) && errors[0] }"
              v-else-if="field.type === 'textarea'"
            />
            <select
              :aria-label="`${field.label}`"
              :value="field.value"
              :id="field.name"
              role="select"
              :name="field.name"
              :class="{ error: Array.isArray(errors) && errors[0] }"
              @change="(event) => {selectChangeHandler(field, event.target.value)}"
              v-else-if="field.type === 'select'"
            >
              <option value="" disabled v-if="field.placeholder || !field.value">{{ field.placeholder ? field.placeholder : 'Select' }}</option>
              <option :value="option.value" v-for="option in field.options" :key="option.label">{{ option.label }}</option>
            </select>
            <div :class="['checkbox-wrapper', { error: Array.isArray(errors) && errors[0] }]" v-else-if="field.type === 'checkbox'">
              <div v-for="option in field.options" :key="`checkbox-${option.value}`" class="checkbox">
                <input
                  :aria-label="`${field.label}`"
                  :id="`${field.name}-${option.value}`"
                  role="checkbox"
                  v-model="field.value"
                  :value="option.value"
                  :name="field.name"
                  type="checkbox"
                />
                <label :for="`${field.name}-${option.value}`">{{ option.label }}</label>
              </div>
            </div>
            <div :class="['radio-wrapper', { error: Array.isArray(errors) && errors[0] }]" v-else-if="field.type === 'radio'">
              <div v-for="option in field.options" :key="`radio-${option.value}`" class="radio">
                <input
                  :aria-label="`${field.label}`"
                  :id="`${field.name}-${option.value}`"
                  role="radio"
                  v-model="field.value"
                  :value="option.value"
                  :name="field.name"
                  type="radio"
                />
                <label :for="`${field.name}-${option.value}`">{{ option.label }}</label>
              </div>
            </div>
            <div class="consent-wrapper" v-else-if="field.type === 'consent'">
              <input
                :aria-label="`${field.label}`"
                :id="`${field.name}`"
                role="checkbox"
                v-model="field.value"
                :name="field.name"
                :value="field.value"
                type="checkbox"
                class="consent"
              />
            </div>
            <span
              class="field-icon material-icons"
              v-if="field.icon && field.iconPosition !== 'left'"
              :class="{ 'file-icon': field.dataType && field.dataType === 'file', 'icon-left': field.iconPosition === 'left' }"
            >
              {{ field.icon }}
            </span>
          </div>
          <div class="help-wrapper" :class="{ 'tooltip-left': tooltipLeft }" v-if="field.type !== 'consent'">
            <div class="help material-icons" v-if="field.tooltip" @mouseover="trackAmplitudeToolTipEvent(field)">
              help
              <span class="tooltip">{{ field.tooltip }}</span>
            </div>
            <div class="help inactive" v-else></div>
          </div>
        </div>
        <div class="error-wrapper" v-if="Array.isArray(errors) && errors[0]">
          <span class="error-message">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>
      <h1 v-else-if="typeof field === 'string'">{{ field }}</h1>
    </div>
    <slot name="after-fields" />
    <div class="controls" :class="{ 'flex-end': !previousButtonEnabled, pad: !hasHelpers }">
      <BackButton v-if="previousButtonEnabled" :backButtonEmits="backButtonEmits" @click="$emit('back-click')" />
      <button aria-label="Submit button" class="btn btn-primary" @click="validate().then(valid => submit(valid))" :disabled="!submitEnabled">
        {{ submitButtonText }}
        <span class="material-icons"> arrow_right_alt </span>
      </button>
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Spinner from '@/components/Spinner'
import DateInput from './DateInput'
import CompanySearch from './CompanySearch'
import BackButton from '@/components/BackBtn'
import FileInput from './FileInput'
import MultiSelect from './MultiSelect'
import CurrencyField from './CurrencyField'
import { countriesOptions } from '@/utils/countries'
import { getMetaData } from '@/api/metaData/index'

export default {
  components: {
    Spinner,
    DateInput,
    BackButton,
    MultiSelect,
    CurrencyField,
    CompanySearch,
    ValidationObserver,
    ValidationProvider,
    FileInput
  },
  props: {
    form: {
      type: Array,
      required: true
    },
    tooltipLeft: {
      type: Boolean
    },
    loading: {
      type: Boolean
    },
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    info: {
      type: String
    },
    previousButtonEmits: {
      type: Boolean,
      default: false
    },
    backButtonEmits: {
      type: Boolean,
      default: false
    },
    previousButtonEnabled: {
      type: Boolean,
      default: true
    },
    submitButtonText: {
      type: String,
      default: 'Submit'
    },
    splitDataObject: {
      type: Boolean,
      default: true
    },
    iloSubTitles: {
      type: Array
    },
    changeBeforeSubmit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      countriesOptions,
      displayedSections: [],
      submitEnabled: !this.changeBeforeSubmit,
      fetchedFormMetadata: {}
    }
  },
  computed: {
    hasHelpers() {
      return this.form.filter(field => field.tooltip).length
    }
  },
  methods: {
    /**
     * Handles changes for select inputs.
     * Finds any waterfall selects and updates them if need be.
     * @param {Object} field - The field object
     * @param {String} value - The value of the field
    */
    async selectChangeHandler(field, value) {
      field.value = value
      for (const formField of Object.values(this.form)) {
        if (formField.metadata?.requiredParentField === field.name) {
          try {
            const { data } = await getMetaData([formField.metadata.optionsType], value)
            formField.options = data[formField.metadata.optionsType]
            formField.value = ''
          } catch (e) {
            console.log(e)
          }
        }
      }
    },
    shouldDisplay(currentField) {
      if (currentField?.hidden) return false
      if (currentField.type === 'select' && !currentField.options.length) return false
      if (!currentField.dependsOn || !this.form) return true

      if (Array.isArray(currentField.dependsOn)) {
        return !currentField.dependsOn
          .map(dependantField => {
            const foundField = this.form.find(formField => formField.name === dependantField.field)

            if (!foundField) {
              throw new Error(`No dependant field found with name ${dependantField.field} for field ${currentField.name}.`)
            }

            if (typeof dependantField.value === 'function') {
              return dependantField.value(foundField)
            }

            return foundField.value === dependantField.value
          })
          .includes(false)
      } else {
        const foundField = this.form.find(formField => formField.name === currentField.dependsOn)

        if (foundField) return true

        return foundField.value
      }
    },
    pushToFormData(formData, { name, value, dataType }) {
      let newValue = value
      if (Array.isArray(value)) {
        newValue = value.map(option => option.value)
      } else if (dataType === 'number' && typeof value === 'string') {
        newValue = parseInt(value.replaceAll(',', ''))
      }

      if ((typeof newValue === 'string' && !newValue.length) || (typeof newValue === 'number' && !String(newValue).length)) {
        newValue = null
      }

      if (dataType === 'currency' && typeof newValue === 'string') {
        newValue = parseInt(value.replaceAll(',', ''))
      }

      if (this.splitDataObject && name.includes('.')) {
        const objectDepth = name.split('.')
        // only works for single depth objects for now
        if (!formData[objectDepth[0]]) {
          formData[objectDepth[0]] = {}
        }
        formData[objectDepth[0]][objectDepth[1]] = newValue
      } else {
        formData[name] = newValue
      }
    },
    shouldDisplaySectionName(field) {
      if (!field || !field.section || !field.section.displayName) {
        return false
      } else if (!this.displayedSections.length || !this.displayedSections.find(section => section.formSectionId === field.formSectionId)) {
        this.displayedSections.push(field)
        return true
      } else if (this.displayedSections.find(section => section.fieldDefinitionId === field.fieldDefinitionId)) {
        return true
      }

      return false
    },
    displaySectionName(field) {
      if (!field || !field.section || !field.section.displayName) {
        return ''
      }
      return field.section.displayName
    },
    mapFormData() {
      const formData = {}
      this.form.filter(item => typeof item !== 'string' && item.name).forEach(item => this.pushToFormData(formData, item))
      return formData
    },
    handleNextPage() {
      return this.$router.push({ name: 'your-business-form' })
    },
    checkSubmit() {
      if (!this.submitEnabled) this.submitEnabled = true
    },
    submit(valid) {
      if (valid) {
        this.$emit('submit', this.mapFormData())
      } else {
        this.scrollToFirstInvalidField()
      }
    },
    scrollToFirstInvalidField() {
      const invalidElements = document.querySelectorAll('.invalid.visable')

      if (invalidElements && invalidElements.length) {
        invalidElements[0].scrollIntoView({ behavior: 'smooth' })
      }
    },
    trackAmplitudeToolTipEvent(formItem) {
      this.$ma.trackEvent({
        eventType: 'Click info button',
        eventProperties: {
          question: formItem.label || ''
        }
      })
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.form-wrapper {
  position: relative;
  margin-top: 50px;

  p {
    margin-bottom: 32px;
  }
  span.sm {
    font-size: 12px;
    width: 100%;
  }

  .ilo-subtitles {
    margin-bottom: 30px;
  }
  .field-wrapper {
    position: relative;

    .input-wrapper {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: flex-end;
      padding-right: 30px;

      @media only screen and (max-width: $breakpoint-sm-max) {
        padding: 0;
      }

      &.input-wrapper-consent {
        width: 30px;
        padding: 0;

        @media only screen and (max-width: $breakpoint-sm-max) {
          margin-right: 0;
        }
      }
    }

    /deep/.field {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 35px 0;

      @media only screen and (max-width: $breakpoint-sm-max) {
        flex-direction: column;

        &.field-consent {
          flex-direction: row;
        }
      }

      &.field-consent {
        flex-direction: row-reverse;
        height: 20px;

        @media only screen and (max-width: $breakpoint-xs-max) {
          height: 40px;
        }
        label {
          color: var(--color-primary-300);
          font-size: 14px;
        }
        input {
          background-color: transparent;
          -webkit-appearance: none;
          -moz-appearance: none;
          -o-appearance: none;
          appearance: none;

          &:checked {
            background-color: transparent;
            -webkit-appearance: none;
            -moz-appearance: none;
            -o-appearance: none;
            &::after {
              content: 'check_box';
              opacity: 1;
              transform: scale(1);
            }
          }
          &::after {
            opacity: 0;
            transition: all 0.3s ease-in-out;
            // font-family: 'Material Icons',sans-serif;
            content: 'check_box_outline_blank';
            display: flex;
            height: 100%;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            transform: scale(0);
          }
        }
      }

      .file-field {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0;
        justify-content: flex-end;

        @media only screen and (max-width: $breakpoint-sm-max) {
          width: 100%;
        }

        input {
          width: 100%;
          padding: 15px;
        }

        .file-list {
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          margin: 1px 0;
          padding: 4px 0;
          border-bottom: 1px solid var(--color-secondary-50);
          .file-name {
            display: flex;
            & ~ .material-icons {
              cursor: pointer;
            }
          }
          .material-icons {
            margin-right: 12px;
          }
        }
      }

      label {
        font-size: 16px;
        width: 100%;
        display: flex;
        padding-top: 18px;
        padding-right: 18px;

        @media only screen and (max-width: $breakpoint-sm-max) {
          width: 100%;
        }

        span.empty {
          width: 16px;
          height: 16px;
          margin-right: 12px;

          @media only screen and (max-width: $breakpoint-sm-max) {
            margin-right: 0px;
          }
        }

        span.material-icons {
          font-size: 16px;
          opacity: 0;
          position: absolute;
          transition: all 0.5s;
          transform: scale(0);
          padding-top: 1px;
          &.valid {
            color: var(--color-secondary-500);
            margin-right: 12px;
            scroll-margin: 40px;
          }
          &.invalid {
            scroll-margin: 40px;
            color: var(--color-error-500);
            margin-right: 12px;
          }
          &.visable {
            scroll-margin: 40px;
            opacity: 1;
            transform: scale(1);
          }
        }
      }

      .sbx-searchbox {
        z-index: 1;
      }

      textarea,
      input,
      select,
      .multiselect,
      .sbx-searchbox {
        width: 100%;
        scroll-margin: 40px;
        min-height: 56px;

        @media only screen and (max-width: $breakpoint-sm-max) {
          width: 100%;
        }

        &.has-icon-left {
          padding-left: 30px;
        }

        &.has-icon-right {
          padding-right: 30px;
        }
        &::placeholder {
          color: var(--color-primary-300);
        }
      }

      .multiselect {
        .multiselect__tags {
          border: 1px solid $input-border-color;
          min-height: 56px;
        }
        .multiselect__tag {
          background-color: $color-primary;
        }
        .multiselect__tag-icon {
          background-color: $color-primary;
          &::after {
            color: $color-white;
          }
        }

        .multi-select-element {
          font-size: 16px;
        }

        .multiselect__input {
          border: none;
        }

        .multiselect__option.multiselect__option--highlight {
          background-color: $color-primary;
          &::after {
            background-color: $color-primary;
          }
        }
      }

      .radio-wrapper,
      .checkbox-wrapper {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1rem;
        row-gap: 1rem;

        @media only screen and (max-width: $breakpoint-sm-max) {
          width: 100%;
        }

        .radio,
        .checkbox {
          width: 100%;

          label {
            width: 100%;
            padding: 20px;
            border: 1px solid $input-border-color;
            color: $color-primary;
            border-radius: 8px;
            display: block;
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
              background-color: $color-primary;
              color: $color-white;
              transform: scale(1.02);
              box-shadow: $lift-box-shadow;
            }
          }
          input {
            opacity: 0;
            position: absolute;

            &:focus {
              + label {
                background-color: $color-primary;
                color: $color-white;
              }
            }
          }
        }
      }

      .consent-wrapper {
        width: 100%;

        .consent {
          width: 16px;
          height: 0px;
          outline: none;
          -webkit-appearance: auto;
          background-color: $color-primary;
        }
      }

      input[type='checkbox']:checked + label {
        color: $color-white;
        background-color: $color-primary;
      }

      input[type='radio']:checked + label {
        color: $color-white;
        background-color: $color-primary;
      }

      .help-wrapper {
        padding-top: 16px;
        z-index: 10;

        @media only screen and (max-width: $breakpoint-sm-max) {
          padding-top: 0;
        }

        &.tooltip-left {
          padding-left: 36px;

          div.help {
            position: absolute;
            right: 0;

            span.tooltip {
              left: -250px;

              &::after {
                top: 50%;
                left: 100%;
                border-color: transparent transparent transparent $color-primary;
              }
            }
          }
        }

        div.help {
          color: $color-primary;
          width: 26px;
          height: 26px;
          border-radius: 50%;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: all 0.5s;

          @media only screen and (max-width: $breakpoint-sm-max) {
            position: absolute;
            right: 0;
            top: 12px;
          }

          &:hover {
            color: $color-form-tooltips;
            transform: translateX(4px);
          }

          &.inactive {
            background-color: transparent;
            cursor: inherit;
          }

          span.tooltip {
            left: 42px;
            width: 240px;
            background-color: $color-form-tooltips;
            color: $color-form-tooltips-text;
            text-align: center;
            border-radius: 6px;
            position: absolute;
            font-family: 'Public Sans', sans-serif;
            font-size: 14px;
            line-height: 18px;
            padding: 0.875rem;
            white-space: initial;
            z-index: 1;
            transition: all 0.3s;
            transform: scale(0);

            @media only screen and (max-width: 1444px) {
              width: 180px;
            }

            @media only screen and (max-width: 1300px) {
              bottom: 32px;
              left: -76px;
            }
            @media only screen and (max-width: 1080px) {
              left: -192px;
              bottom: auto;
            }

            &::after {
              content: ' ';
              position: absolute;
              top: 50%;
              right: 100%;
              margin-top: -5px;
              border-width: 5px;
              border-style: solid;
              border-color: transparent $color-form-tooltips transparent transparent;

              @media only screen and (max-width: $breakpoint-sm-max) {
                top: 50%;
                left: 100%;
                border-color: transparent transparent transparent $color-form-tooltips;
              }
            }
          }

          &:hover .tooltip {
            transform: scale(1);
          }
        }
      }

      .field-icon {
        position: absolute;
        right: 40px;
        top: 16px;
        color: var(--color-primary-300);
        pointer-events: none;

        @media only screen and (max-width: $breakpoint-sm-max) {
          right: 12px;
        }

        &.icon-left {
          left: 12px;
          top: calc(50% - 14px);

          &.currency {
            color: var(--color-primary-500);
            font-size: 20px;
          }
        }
      }
    }
  }

  .controls {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;

    &.flex-end {
      justify-content: flex-end;
    }

    &.pad {
      padding-right: 54px;

      @media only screen and (max-width: $breakpoint-sm-max) {
        padding: 0;
      }
    }

    /deep/.btn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 56px;
      border-radius: 30px;

      &.btn-back {
        padding: 20px;
        width: 120px;
      }

      &:hover {
        i.icon.material-icons,
        span.material-icons {
          transform: translateX(12px);
          transition: transform 0.5s;
        }
        i.icon-left.material-icons,
        span.icon-left.material-icons {
          transform: translateX(-12px);
        }
      }

      .material-icons {
        margin-left: 22px;
        font-size: 16px;
      }

      .icon-left.material-icons {
        margin-right: 22px;
        margin-left: 0;
      }
    }
  }

  .error {
    border: 1px solid var(--color-error-600);
    border-radius: 5px;

    &:focus {
      box-shadow: 0px 0px 1px 1px var(--color-error-600);
    }
  }

  .error-wrapper {
    position: absolute;
    bottom: -30px;
    right: 60px;

    @media only screen and (max-width: $breakpoint-sm-max) {
      right: 0;
    }

    .error-message {
      color: var(--color-error-600);
      text-align: right;
    }
  }
}
</style>
