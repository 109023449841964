<template>
  <input :id="field.name" :name="field.name" role="input" v-model="formattedValue" @input="$emit('input', formattedValue)" v-currency type="text" />
</template>

<script>
import { formatToCurrency } from '@/utils/currency'

export default {
  name: 'CurrencyField',
  props: {
    field: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      formattedValue: formatToCurrency(this.field.value)
    }
  },
  watch: {
    'field.value': function (newVal) {
      if (newVal !== this.formattedValue) {
        this.formattedValue = formatToCurrency(this.field.value)
      }
    }
  }
}
</script>

<style>
input {
  padding-left: 30px;
}
</style>
