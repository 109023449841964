<template>
  <div class="file-field field">
    <input
      v-model="field.value"
      :id="field.name"
      role="input"
      :name="field.name"
      :class="{ 'has-icon': field.icon }"
      :type="field.dataType"
      @change="(event) => emitFiles(field, event)" />

    <div class="file-list" v-for="file in field.files" :key="`${field.name}-file-${file.documentId || file.id}`">
      <div class="file-name">
        <span class="material-icons">
          insert_drive_file
        </span>
        {{file.name}}

      </div>
      <span class="material-icons" @click="() => emitDelete(file)">
        delete
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    field: {
      type: Object,
      required: true
    },
    value: {
      type: Array,
      required: false
    }
  },
  methods: {
    emitFiles(field, event) {
      if (!field || !event || !event.target || !event.target.files) return
      this.$emit('files-selected', { field: field.name, files: event.target.files })
    },
    emitDelete(file) {
      this.$emit('delete-file', file)
    }
  }
}
</script>
