<template>
  <div class="date-input-wrapper">
    <select name="day" v-model="day" v-if="dataType === 'day'" data-testid="select-day">
      <option :value="null" disabled selected>Day</option>
      <option v-for="day in days" :key="day.value" :value="day.value" data-testid="option-day">
        {{ day.label }}
      </option>
    </select>
    <select name="month" v-model="month" data-testid="select-month">
      <option :value="null" disabled selected>Month</option>
      <option v-for="month in months" :key="month.value" :value="month.value" data-testid="option-month">
        {{ month.label }}
      </option>
    </select>
    <select name="year" v-model="year" data-testid="select-year">
      <option :value="null" disabled selected>Year</option>
      <option v-for="year in years" :key="year" :value="year" data-testid="option-year">
        {{ year }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    returnType: String,
    dataType: String
  },
  data() {
    return {
      day: null,
      month: null,
      year: null,
      days: [],
      months: [],
      years: []
    }
  },
  mounted() {
    this.generateDays()
    this.generateMonths()
    this.generateYears()

    const currentDate = new Date(this.value)
    if (currentDate && !isNaN(currentDate.getTime())) {
      this.$nextTick(() => {
        if (this.dataType === 'day') {
          this.day = currentDate.getDate()
        } else {
          this.day = 1
        }
        this.month = currentDate.getMonth()
        this.year = currentDate.getFullYear()
      })
    }
  },
  computed: {
    selectedDate() {
      return `${this.day}${this.month}${this.year}`
    }
  },
  watch: {
    selectedDate() {
      if ((this.dataType === 'day' && this.day && this.year && this.month >= 0) || (this.year && this.month >= 0)) {
        this.$emit('input', this.getDate())
      }
    },
    month() {
      this.generateDays()
    },
    year() {
      this.generateDays()
    }
  },
  methods: {
    getDate() {
      if (this.returnType === 'ISO') {
        const date = new Date(this.year, this.month, this.day)

        date.setUTCHours(0, 0, 0, 0)

        return date.toISOString()
      } else if (this.returnType === 'UTC') {
        // Using ILO form.
        const date = new Date(Date.UTC(this.year, this.month, this.day))

        return date.toISOString()
      } else if (this.returnType === 'dd/mm/yyyy') {
        const date = new Date(this.year, this.month, this.day)

        return date.toJSON().slice(0, 10).replaceAll('-', '/')
      }
      if (this.returnType === 'mm/yyyy') {
        const date = new Date(this.year, this.month + 1, this.day)

        date.setUTCHours(0, 0, 0, 0)

        return date.toISOString()
      } else {
        const date = new Date(this.year, this.month, this.day)

        date.setHours(12)

        return date.getTime() / 1000
      }
    },
    emitDate() {
      if (this.year && this.month) {
        const date = this.getDate()
        this.$emit('input', date)
      }
    },
    generateDays() {
      const year = this.year || new Date().getFullYear()
      const month = this.month || new Date().getMonth()
      const date = new Date(year, month + 1, 0).getDate()

      const days = []
      for (let i = 1; i <= date; i++) {
        days.push({ label: i, value: i })
      }
      this.days = days

      // If user pick 31 January then change to February it will show 28
      if (this.day > date) {
        this.day = date
      }
    },
    generateMonths(short) {
      const format = short ? 'short' : 'long'
      this.months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(function (month) {
        return {
          label: new Date(2000, month).toLocaleString({}, { month: format }),
          value: month
        }
      })
    },
    generateYears() {
      const year = new Date().getFullYear()
      const years = []
      for (let i = year; i > year - 100; i--) {
        years.push(i)
      }
      this.years = years
    }
  }
}
</script>

<style lang="scss" scoped>
.date-input-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;

  select[name='month'],
  select[name='day'] {
    margin-right: 8px;
  }
}
</style>
