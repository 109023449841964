<template>
  <button class="btn btn-back" @click.prevent="backBtnClickHandler" @keyup.enter="backBtnClickHandler">
    <i class="icon material-icons icon-left">arrow_back</i>
    {{ text }}
  </button>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { sidebarRoutine, sidebarGetters } from '../store/modules/sidebar/routines'
export default {
  name: 'BackBtn',
  props: {
    fallback: {
      type: [Object, String],
      default() {
        return { name: 'dashboard' }
      }
    },
    text: {
      type: String,
      default: 'Back'
    },
    backButtonEmits: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      sidebarGlobalState: sidebarGetters.SIDEBAR_STATUS
    })
  },
  methods: {
    ...mapActions({
      handleToggleSidebar: sidebarRoutine.TRIGGER
    }),
    backBtnClickHandler() {
      if (this.backButtonEmits) {
        this.$emit('click')
      } else {
        const prevRoute = this.$route
        this.$router.go(-1)
        setTimeout(() => {
          const currentRoute = this.$route
          if (prevRoute === currentRoute) {
            this.$router.push(this.fallback)
          }
        }, 300)
      }
    }
  }
}
</script>
<style scoped lang="scss">
i.icon {
  font-size: 2rem;
}
</style>
