<template>
  <vue-instant
    :name="field.name"
    :placeholder="field.placeholder || ''"
    v-model="typeahead.value"
    :suggestion-attribute="typeahead.suggestionAttribute"
    :show-autocomplete="true"
    :showPlaceholder="!field.disabledTextAutoComplete"
    :autofocus="false"
    :findSimilar="false"
    :allowSimilar="true"
    :suggestions="typeahead.suggestions"
    :suggestOnAllWords="false"
    @selected="selectCompany"
    @enter="handleOnEnter"
  ></vue-instant>
</template>

<script>
import { findCompanies } from '@/api/company'

export default {
  props: {
    value: {
      type: [String, Object]
    },
    field: {
      type: Object,
      required: true
    },
    setFlags: {
      type: Function,
      required: false
    }
  },
  data() {
    return {
      company: null,
      typeahead: {
        value: '',
        suggestionAttribute: 'title',
        suggestions: this.getSuggestions
      }
    }
  },
  computed: {
    companyDetails() {
      return `${this.company}|${this.typeahead ? this.typeahead.value : ''}`
    }
  },
  watch: {
    companyDetails() {
      this.emitCompanyDetails()
    }
  },
  methods: {
    emitCompanyDetails() {
      if (!this.company && this.typeahead.value && this.typeahead.value.length) {
        this.$emit('input', {
          companyName: this.typeahead.value,
          sourceType: 'Entered'
        })
      } else if (!this.company) {
        this.$emit('input', null)
      } else {
        this.$emit('input', {
          companyName: this.company.name,
          registrationNumber: this.company.companyRegistrationNumber,
          country: this.company.countryCode,
          sourceType: 'FromRegistrar',
          ...this.company
        })
      }
    },
    countyrCode(c) {
      return c.countryCode ? `(${c.countryCode})` : ''
    },
    companyRegistration(c) {
      return c.companyRegistrationNumber ? `(ABN: ${c.companyRegistrationNumber})` : ''
    },
    companyRegistrationNumber(c) {
      return c.companyRegistrationNumber ? `(${c.companyRegistrationNumber})` : ''
    },
    getSuggestions(value, callback) {
      this.company = null
      if (value === '') return
      const requestData = {
        companyName: value,
        skip: 0,
        take: 0
      }
      const companiesPromise = findCompanies(requestData)
        .then(response =>
          response.data.companies
            .sort((a, b) => (a.name.toLowerCase().indexOf(value.toLowerCase()) > b.name.toLowerCase().indexOf(value.toLowerCase()) ? -1 : 1))
            .map(c => {
              if (this.$config.whitelabel.components.companySearch.countryCode) {
                return { ...c, title: `${c.name} ${this.countyrCode(c)} ${this.companyRegistrationNumber(c)}` }
              } else {
                return { ...c, title: `${c.name} ${this.companyRegistration(c)}` }
              }
            })
        )
        .catch(e => e)
      callback(companiesPromise)
    },
    selectCompany(company) {
      this.company = company
    },
    handleOnEnter(ev) {
      ev.preventDefault()
    },
    clearCompany() {
      this.company = null
    }
  }
}
</script>
