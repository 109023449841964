<template>
  <multiselect
    :data-cy="field.name"
    v-model="field.value"
    v-if="field.type === 'multiselect'"
    :name="field.name"
    :options="field.options"
    placeholder=""
    :multiple="true"
    @close="$emit('blur')"
    label="label"
    track-by="value"
  />
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
  components: { Multiselect },
  props: {
    field: {
      type: Object,
      required: true
    },
    value: {
      type: Array,
      required: false
    }
  }
}
</script>
